import React from "react";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";

const Div = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  .hero-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    position: relative;
    overflow: hidden;
    padding: 50px 1em; /* changed from padding: 30px 1em; */
    min-height: 300px;
    h1 {
      font-size: 50px;
      color: ${colors.lightGray};
      margin-bottom: 0;
      font-size: ${props => props.fontSize};
    }
    h2 {
      font-size: 36px;
    }
    .nowrap {
      white-space: nowrap;
    }
    @media (min-width: ${breakpoints.tablet}) {
      padding: 1em 2.5em;
      h1 {
        font-size: 70px;
      }
      h2 {
        font-size: 48px;
      }
    }
    @media (min-width: ${breakpoints.desktop}) {
      padding: 1em 4.5em 0;
    }
  }
`;

const Hero = ({
  headline,
  sub,
  bgcolor,
  textcolor,
  id,
  button,
  backButton,
  fontSize,
  ...remainProps
}) => {
  return (
    <Div {...remainProps}>
      {backButton ? (
        <section className="hero-container">
          <h1 style={{fontSize: fontSize}}>
            {ReactHtmlParser(headline)}
          </h1>
          <h2>{ReactHtmlParser(sub)}</h2>
          <div>{button}</div>
        </section>
      ) : (
        <section className="hero-container">
          <h1>
            {ReactHtmlParser(headline)}
          </h1>
          <h2>{ReactHtmlParser(sub)}</h2>
          <div>{button}</div>
        </section>
      )}
    </Div>
  );
};

export default Hero;
