import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../style-utilities/variables";

const StyledA = styled.a`
  grid-area: ContactButton;
  display: inline-block;
  width: auto;
  background-color: ${colors.primary};
  font-size: 14px;
  font-family: ${fonts.subHead};
  letter-spacing: 3px;
  cursor: pointer;
  margin-top: 0.5em;
  text-transform: uppercase;
  text-align: center;
  border-radius: 52px;
  letter-spacing: 3px;
  border: 1px solid ${colors.primary};
  padding: 13px 25px;
  color: white !important;
  text-decoration: none;
  white-space: nowrap;
  font-weight: bold;
  :hover {
    text-decoration: none;
    background-color: ${colors.secondaryRed};
    transition: 0.3s all ease;
    color: white;
  }
`;

const StyledLink = styled(Link)`
  grid-area: ContactButton;
  font-size: 14px;
  font-family: ${fonts.subHead};
  color: white !important;
  display: inline-block;
  width: auto;
  background-color: ${colors.primary};
  letter-spacing: 3px;
  cursor: pointer;
  margin-top: 0.5em;
  text-transform: uppercase;
  text-align: center;
  border-radius: 52px;
  letter-spacing: 3px;
  padding: 13px 25px;
  border: 1px solid ${colors.primary};
  text-decoration: none;
  white-space: nowrap;
  font-weight: bold;
  :hover {
    text-decoration: none;
    background-color: ${colors.secondaryRed};
    transition: 0.3s all ease;
    color: white;
  }
`;

const ButtonRed = ({
  textLabel,
  pageLink,
  pageTarget,
  clickFunction,
  className,
  ...remProps
}) => {
  if (pageLink) {
    const splitLink = pageLink.split("");

    if (splitLink[0] === "/") {
      return <StyledLink to={pageLink} className={className}>{textLabel}</StyledLink>;
    } else {
      return (
        <StyledA href={pageLink} className={className}>
          {textLabel}
        </StyledA>
      );
    }
  }

  if (clickFunction) {
    return <StyledA onClick={clickFunction}>{textLabel}</StyledA>;
  }
};

export default ButtonRed;