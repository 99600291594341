import React, { Component } from "react";
import styled from "styled-components";
import { colors, fonts, breakpoints } from "../style-utilities/variables";
// import components
import EmailSignupForm from "./EmailSignupForm";
import { Link } from "gatsby";
import ButtonRed from "./ButtonRed";

const StyledContactBanner = styled.div`
  background: ${colors.white};
  border-bottom: ${props =>
    props.first ? `2px solid ${colors.secondaryGray}` : "0px"};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 450px;
  text-align:center;
  h1 {
    color: black;
  }

`;


const ContactBanner = ({headline, cta, url}) => {
  return (
    <StyledContactBanner>
      <h2>{headline ? headline : "Let's Start a Conversation"}</h2>
      <ButtonRed
        textLabel={cta ? cta : "CONTACT US"}
        pageTarget="_blank"
        pageLink={url ? url : "/contact"}
      />
    </StyledContactBanner>
  );
};

export default ContactBanner;
