import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints } from "../style-utilities/variables";

const StyledLink = styled(Link)`
  grid-area: ContactButton;
  display: inline-block;
  background-color: white;
  font-size: 14px;
  font-family: "daytona-bold";
  letter-spacing: 3px;
  width: auto;
  cursor: pointer;
  margin-top: 0.5em;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid ${colors.lightGray};
  border-radius: 25px;
  letter-spacing: 3px;
  padding: 13px 25px;
  color: ${colors.black};
  text-decoration: none;
  white-space: nowrap;
  font-weight: bold;

  :hover {
    border: 1px solid ${colors.secondaryRed};
    background-color: ${colors.secondaryRed};
    transition: 0.3s all ease;
    text-decoration: none;
    color: ${colors.white};
  }
  .linkColor {
    color: "#0f0";
  }
`;

const StyledA = styled.a`
  grid-area: ContactButton;
  display: inline-block;
  background-color: white;
  letter-spacing: 3px;
  width: auto;
  cursor: pointer;
  margin-top: 0.5em;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid ${colors.lightGray};
  border-radius: 25px;
  letter-spacing: 3px;
  padding: 13px 25px;
  color: ${colors.black};
  text-decoration: none;
  white-space: nowrap;
  font-weight: bold;

  :hover {
    border: 1px solid ${colors.secondaryRed};
    background-color: ${colors.secondaryRed};
    transition: 0.3s all ease;
    text-decoration: none;
    color: ${colors.white};
  }
  .linkColor {
    color: "#0f0";
  }
  `;

const ButtonGrey = ({ textLabel, textColor, pageLink, externalLink, ...remProps }) => {
  if (pageLink) {
    return (
      <StyledLink
        to={pageLink}
        className="linkColor"
        style={{
          color: textColor
        }}
      >
        {textLabel}{" "}
      </StyledLink>
    );
  } else {
    return (
      <StyledA href={externalLink} target="_blank" rel="noreferrer noopener">
        {textLabel}
      </StyledA>
    );
  }
};

export default ButtonGrey;
